@import 'sass/theme'
    
.about
    width: 100%

    \:global
        .text
            margin-top: 7.6%
            position: relative
            padding-top: 10.75%
            padding-bottom: 100px

        
        .text.image > div
            display: flex

            @media (max-width: $tablet-small)
                flex-wrap: wrap-reverse

            > div:nth-child(1)
                flex: 0 0 46%
                padding-right: 8%

                @media (max-width: $tablet-small)
                    flex-basis: 100%
                    padding-right: 0
                    margin-top: 30px

            > div:nth-child(2)
                flex: 0 0 54%

                @media (max-width: $tablet-small)
                    flex-basis: 100%

            .lazyload
                background-size: cover


            

