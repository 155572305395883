@import 'sass/theme'
    
#footer
    margin-bottom: 192px

    \:global
        .center
            > div > div
                text-align: center
                padding-top: 70px
                padding-bottom: 70px

                .social
                    display: inline-block
                    width: auto
                    margin-bottom: 20px

                    h6,
                    ul,
                    li
                        float: left

                    li
                        margin-left: 40px

                    h6
                        line-height: 29px
                        font-weight: 100

                    a:hover
                        svg path,
                        svg polyline
                            fill: $color-secondary

                .actions
                    margin-bottom: 50px

                    a
                        color: #000

                p
                    font-weight: 100
                        

                        
        