.form
    > div
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        align-content: flex-start

    \:global
        input[type="text"],
        input[type="number"],
        textarea
            width: 100% 
            font-family: "Helvetica Neue"
            font-weight: 400
            font-size: 1rem
            line-height: 150% 
            border: none
            outline: none
            background: none
            resize: none    
            padding-left: 16px
            padding-bottom: 10px
            border-bottom: 1px solid #000

        * + input[type="text"],
        * + input[type="number"],
        * + textarea
            margin-top: 40px

        input[type="text"],
        input[type="number"],
        textarea
            &::placeholder 
                color: #000
                opacity: 1

            &:-ms-input-placeholder
                color: #000

            &::-ms-input-placeholder
                color: #000

        .half
            flex: 0 0 45%

        .file
            position: relative
            background: rgba(9, 19, 33, 0.06)
            padding: 11px
            flex: 0 0 100%
            
            input
                float: right
                display: none

            .select,
            .remove
                float: right 
                cursor: pointer
                font-size: 1rem
                text-transform: uppercase
                font-weight: 500

            .name
                font-size: 1rem

            span 
                cursor: pointer
                word-break: break-word

                a 
                    text-decoration: none
                    color: #00DDFF

            
            &::after
                content: ""
                display: block
                clear: both

            + .file
                margin-top: 33px

        .checkbox
            position: relative
            margin-top: 40px
            padding-left: 34px

            span
                position: absolute
                left: 0
                top: 0
                width: 24px
                height: 24px
                background: #F4FF00
                cursor: pointer

            &.active
                span
                    background-image: url(../../../../media/check.svg)
                    background-repeat: no-repeat
                    background-position: 4px 6px

            a
                text-decoration: underline

        .required
            flex: 0 0 100%
            margin-top: 40px

        .submit
            display: block
            margin-top: 40px
            font-size: 1rem
            text-transform: uppercase
            font-weight: 500
            cursor: pointer

