@import 'sass/theme'

.overlay
    position: fixed
    z-index: 200
    width: 100%
    height: 100%
    left: 0
    top: 0
    background: #000
    overflow: hidden
    animation: fadeIn 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000) forwards
    will-change: opacity, transform

    &:global(.out)
        animation: fadeOut 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000) forwards

    \:global
        .slides
            position: absolute
            left: 10%
            height: 100%
            width: 80%
            

            > div
                position: absolute
                width: 100%
                height: 100%
                transition: transform 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940)

                @at-root :global(.noanimate)#{&}
                    transition-duration: 0s

                > div
                    position: absolute
                    width: 100%
                    height: 100%
                    color: #fff
                    transition: all 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940)

                    .image
                        position: absolute
                        width: 100%
                        height: 100%    
                        background-size: contain
                        background-repeat: no-repeat
                        transition: transform 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940)
                        background-position: center center

                        > div
                            left: 0
                            top: 0
                            position: absolute
                            width: 100%
                            height: 100%
                            transition-duration: 0s
                            background-size: contain
                            background-repeat: no-repeat
                            background-position: center center    

                        @at-root :global(.noanimate)#{&}
                            transition-duration: 0s

                    &.previous
                        opacity: 0.3
                        transform: scale(0.8) translateX(8%)

                    &.next
                        opacity: 0.3
                        transform: scale(0.8) translateX(-8%)

        .button
            display: block
            position: absolute
            top: 0%
            width: 10%
            height: 100%         
            cursor: pointer

            svg
                position: absolute
                left: 50%
                top: 50%
                width: 20px
                height: 36px
                transform: translate(-50%,-50%)

            &.previous
                left: 0

                svg
                    transform: rotate(-180deg)

            &.next
                right: 0

            &:hover
                svg
                    polyline
                        stroke: $color-secondary

        .close
            position: absolute
            z-index: 10
            right: 40px
            top: 20px
            width: 24px
            height: 20px
            cursor: pointer

            &:before,
            &:after
                display: block
                position: absolute
                left: 2px
                top: 9px
                width: 20px
                height: 2px
                background: #fff
                content: ''

            &:before
                top: 2px
                transform: rotate(-45deg)  translate(-5px, 5px)

            &:after
                top: 16px
                transform: rotate(45deg)  translate(-5px, -5px)

            &:hover
                &:before,
                &:after
                    background: $color-secondary
                         

@keyframes fadeIn
    0%  
        opacity: 0.3
        transform: scale(1.8)

    50%
        opacity: 1

    100%
        opacity: 1
        transform: none


@keyframes fadeOut
    0%  
        opacity: 1
        transform: none

    100%
        opacity: 0
        transform: scale(1.4) 