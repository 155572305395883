@import 'sass/theme'
    
.model
    padding-top: 110px

    @media (max-width: $smartphone-small)
        padding-top: 80px

    \:global
        .header
            position: relative

            .border
               top: 38px 
            
            > div
                display: flex
                flex-wrap: wrap

                > div:nth-child(1)
                    flex: 0 0 68.19%
                    padding-right: 4.77%

                    @media (max-width: $tablet)
                        flex: 1

                    @media (max-width: $tablet-small)
                        flex: 0 0 100%

                > div:nth-child(2)
                    position: relative
                    flex: 0 0 31.81%
                    padding: 14.7% 0 20px 0 

                    @media (max-width: $tablet)
                        flex-basis: 277px
                        padding-top: 125px

                    @media (max-width: $tablet-small)
                        flex: 0 0 100%
                        padding-top: 60px 

                    .social
                        position: absolute
                        left: 0
                        top: 0
                        margin-top: 27%

                        a + a
                            margin-left: 10px

                        @media (max-width: $tablet-small)
                            margin-top: 20px

                    .casting
                        position: absolute
                        left: 0
                        top: 0 
                        padding-right: 37px
                        font-weight: 500
                        font-size: 0.75 rem
                        text-transform: uppercase
                        cursor: pointer

                        @media (max-width: $tablet-small)
                            left: inherit
                            right: 20px
                            top: 20px

                        @media (max-width: $smartphone-small)
                            margin-top: -98%
                            top: 0px

                        span
                            position: absolute
                            display: block
                            right: 0
                            top: 4px
                            width: 17px
                            height: 17px
                            background: $color-secondary
                            border-radius: 50%

                            &::before,
                            &::after
                                position: absolute
                                left: 3px
                                top: 7.5px
                                display: block
                                width: 11px
                                height: 2px
                                background: #000
                                content: "" 

                            &::after
                                transform: rotate(90deg)

                        &.active
                            span::after
                                display: none
                    
                    .text
                        position: relative
                        overflow: hidden      

                        &.animate
                            transition: height 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)

                        > span
                            display: block
                            position: absolute
                            left: 0
                            bottom: 0
                            width: 100%
                            height: 120px
                            background: linear-gradient(180deg, rgba(210,224,213,0) 0%, rgba(210,224,213,1) 100%)
                                           
                        
                    .more
                        cursor: pointer
                        font-weight: 500
                        text-transform: uppercase

                    
                    


                > div:nth-child(3)
                    flex: 0 0 100%
                    padding: 20px 0 18px 0

                    ul
                        text-align: center

                        li
                            display: inline-block
                            
                        + ul
                            margin-top: 3px

                            li
                                margin: 0 15px

            .image
                background-size: cover

        .images
            margin-top: 96px

            > div
                position: relative

                &:nth-child(1)
                    display: flex
                    justify-content: space-between

                    @media (max-width: $tablet-small)
                        .actions + .actions
                            display: none
                            
                &:nth-child(2)
                    margin-top: 55px

                    .border
                        top: 70px
                        bottom: 55px

            .list
                > div 
                    > div
                        position: absolute
                        left: 0
                        top: 0
                        width: 100%
                        height: 100%
                        background-size: cover
                        transition: transform 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940), opacity 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)
                        cursor: pointer

                    &:hover
                        > div
                            transform: scale(1.05)
                            transition-duration: 0.3s 

                    > span
                        position: absolute
                        right: 20px
                        top: 20px 
                        display: block
                        width: 24px
                        height: 24px
                        border: 2px solid #F4FF00
                        background-repeat: no-repeat
                        cursor: pointer

                        &.active
                            display: block
                            background: #F4FF00
                            border: none
                            text-align: center
                            line-height: 24px

                    @media (hover: hover)
                        &:hover
                            span
                                display: block

        .videos
            > div
                display: flex
                margin-left: -30px

                > div
                    flex: 1
                    padding-left: 30px    

                    > div
                        position: relative
                        padding-bottom: 56.25%
                        background: #000

                        iframe
                            position: absolute
                            left: 0
                            top: 0
                            width: 100%
                            height: 100%

                + div
                        margin-top: 30px


                    
                    