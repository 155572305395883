@import 'sass/theme'

@font-face
    font-family: "Helvetica Neue"
    src: url("/fonts/7d63ccf8-e0ae-4dee-ad4d-bbc798aa5803.woff2") format("woff2"), url("/fonts/b2c1327f-ab3d-4230-93d7-eee8596e1498.woff") format("woff")
    font-weight: 100

@font-face
    font-family: "Helvetica Neue"
    src: url("/fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"), url("/fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff")
    font-weight: 400

@font-face
    font-family: "Helvetica Neue"
    src: url("/fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"), url("/fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff")
    font-weight: 500
    
*
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box

body
    background: #D2E0D5
    font-family: "Helvetica Neue"
    font-weight: 400
    font-size: 14px
    line-height: 200%
    overflow-y: scroll
    color: #000
    -moz-hyphens: auto
    -webkit-hyphens: auto
    -ms-hyphens: auto
    hyphens: auto

h1, h2, h3, h4, h5, h6
    font-size: 1rem
    line-height: 1rem
    font-weight: normal
    margin: 0

a
    text-decoration: none
    color: #000

p,
ul 
    margin: 0

ul
    list-style: none
    padding: 0

.clickArea
    position: absolute
    z-index: 10
    left: 0
    top: 0
    width: 100%
    height: 100%

.center 
    margin: 0 auto
    max-width: 1340px

    > div
        position: relative
        margin: 0 9%

        &::after    
            content: ""
            clear: both
            display: block

        > div
            position: relative
            max-width: 1100px
            width: 100%
            
        &.left
            margin-right: 4.5%

            > div
                float: left

        &.right
            margin-left: 4.5%

            > div
                float: right
.rich
    * + p,
    * + h2,
    * + h3
        margin-top: 14px

    h2 
        font-weight: 500

    * + h2
        margin-top: 28px

    a
        font-weight: 500
        border-bottom: 1px solid 

        &:hover
            border-bottom-color: $color-secondary
            
span.border
    display: block
    position: absolute
    z-index: -1
    width: 100vw
    top: 0
    bottom: 0
    border: 2px solid $color-secondary

    h1,
    h2 
        position: absolute
        top: 30px
        transform: rotate(-90deg) translateX(-100%)
        transform-origin: left center
        font-size: 2.8125rem
        line-height: 100%
        font-weight: 100
        white-space: nowrap

    @at-root .left #{&}
        border-right: none
        left: -5.4%
        
        h1,
        h2
            left: -6px

    @at-root .right #{&}
        border-left: none
        right: -5.4%

        h1,
        h2
            left: 100%
            margin-left: -3px

.actions
    li
        display: inline-block

    a,
    span
        margin: 0 15px
        padding: 5px 0
        position: relative
        text-transform: uppercase
        font-weight: 500
        font-size: 0.75rem
        line-height: 100%
        color: #808881
        transition: color 0.2s ease-out
        
        &::before,
        &::after
            position: absolute
            bottom: 0
            width: 0
            height: 1px
            background: $color-secondary
            transform: translateY(-1px)
            content: ""
            transition: 0.2s ease-out

        &::before
            left: -15px

        &::after
            right: -15px
        
        &:hover,    
        &.active
            color: #000
            
            &::before,
            &::after
                transition-property: 1s
                width: 50%

            &::before
                left: 0

            &::after
                right: 0

    span
        cursor: pointer

span.action
    display: inline-block
    position: relative
    cursor: pointer
    text-transform: uppercase

    &::before,
    &::after
        position: absolute
        bottom: 0
        width: 0
        height: 1px
        background: $color-secondary
        transform: translateY(-1px)
        content: ""
        transition: 0.2s ease-out

    &::before
        left: -15px

    &::after
        right: -15px
    
    &:hover,    
    &.active
        color: #000
        
        &::before,
        &::after
            transition-property: 1s
            width: 50%

        &::before
            left: 0

        &::after
            right: 0



.lazyload
    opacity: 0
    transition: all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)

    &.loaded
        opacity: 1
        
#app 
    overflow: hidden
    min-height: 100vh

#content
    min-height: 100vh
    padding-bottom: 192px