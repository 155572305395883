@import 'sass/theme'

.contact
    width: 100%

    \:global
        .text
            margin-top: 7.6%
            position: relative
            padding-top: 10.75%
            padding-bottom: 100px

        .text > div
            display: flex
            flex-wrap: wrap

            > div:nth-child(1)
                flex: 0 0 50%
                padding-right: 8%

                @media (max-width: $tablet-small)
                    flex-basis: 100%
                    padding-right: 0

            > div:nth-child(2)
                flex: 0 0 50%

                > div
                    max-width: 404px

                @media (max-width: $tablet-small)
                    flex-basis: 100%
                    margin-top: 30px

/*

 > div:nth-child(1)
 position: relative
 flex: 0 0 100%
 padding-bottom: 50.5859375%
 margin-bottom: 10.45%

 > div
 position: absolute !important

 */        
